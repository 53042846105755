
/*==home-for-sale*/
section.home-for-sale {
  .heading-container {
    margin-bottom: $padding_content;
    justify-content: center;
    h1, h2, h3 {
      margin-bottom: 0;
      align-self: center;
    }
    //.content {
    //  font-weight: 600;
    //}
  }
  .content-wrapper {
    //background-color: #fff;
    width: 100%;
    //background-color: $color_bg_gray_light;
    .brand {
      font-size: 1.25em;
      font-weight: 500;
      //color: $color_black;
      padding: 5px 10px 0px 5px;
      //-webkit-border-radius: 20px;
      //-moz-border-radius: 20px;
      //border-radius: 20px;
      text-decoration: none;
      border-left: none !important;
      position: relative;
      z-index: 1;
      margin-left: 12px;
      .trangle {
        z-index: -1;
        width: 17px;
        height: calc(100% + 6px);
        position: absolute;
        left: -7px;
        transform: skewX(-20deg);
        top: -3px;
      }

      .brand-img {
        display: none;
        margin-right: 10px;
        img {
          width: 40px;
        }
      }
      margin-bottom: 25px;
    }
    .content {
      position: relative;
      z-index: 99;
      width: 100%;
      margin-left: 0px;
      margin-top: 0px;
      //border: 1px solid $color_bg_gray_light;

      padding: 15px;
      .size-4 {
        //color: $color_black;
        text-transform: uppercase;
      }
      .inner-content {
        margin: 10px 0 15px;
      }
      a {
        text-decoration: none;
      }
      .btn {
        margin-top: 0px;
      }
    }
    .placeholder {
      min-height: 250px;
      overflow: hidden;
      .extra-logo {
        margin: 10px;
      }
      .inner-image {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

      }
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        //background: linear-gradient(180deg, $color_red 0%, $color_blue_dark 100%);
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      &:hover {
        .inner-image {
          transform: scale(1.2);
        }
        &:after {
          opacity: 0.9;
        }
      }
    }

    .property-details {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        margin-right: 15px;
        a {
          //padding-left: 35px;
          color: $color_medium_black;
          &.bedroom {
            background: transparent url(../images/Bedrooms@2x.svg) left center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
          }
          &.bathroom {
            background: transparent url(../images/Bathrooms@2x.svg) left center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
          }
          &.garage {
            background: transparent url(../images/Garage@2x.svg) left center no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
          }
        }
      }
    }

  }

  &.bg-gray {
    .content-wrapper {
      background: $color_white;
    }
  }

  @media screen and (max-width: $media_sm) {
    .heading-container {
      text-align: center;
      justify-content: center;
      .btn {
        display: none;
      }

    }
  }
}

body.page-for-sale {
  section.home-for-sale {
    .heading-container .btn {
      display: none;
    }
  }
}
