/*intro*/
section.intro {

  a.back {
    font-size: 14px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    .material-icons {
      position: absolute;
      top: -3px;
      left: -30px;
    }
  }
  .content {
    ul {
      padding: 16px 0 16px 16px;
      li {
        //color: $color_medium_black;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .size-2, .size-3, .size-4, h1, h2, h3, h4 {
    margin-bottom: 24px;
  }

  @media screen and (max-width: $media_sm) {
    .has-parent-link {
      margin-bottom: 50px;
    }
    a.back {
      position: absolute;
      bottom: -24px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 19px;
      color: $color_white;
      //background-color: $color_blue_light;
      height: 50px;
      .material-icons {
        position: relative;
        left: initial;
        top: initial;
        padding-right: 10px;
        &:hover {
          color: $color_white;
        }
      }

      &:hover {
        //background-color: $color_blue;
      }
    }
  }

}

section.intro.intro-summary {
  padding-top: $padding_small;
  padding-bottom: $padding_small;
  @media screen and (max-width: $media_md) {
    &.intro-summary {
      .heading-container {
        h1 {
          br {
            display: none;
          }
        }
      }

    }

  }
}
