/*==video section*/
section.video-section {

  .placeholder {
    position: relative;
    z-index: 9;
    width: 100%;
    padding-top: 57%;

    .play-pause {
      position: absolute;
      margin: 10px auto;
      width: 80px;
      height: 80px;
      //background-color: rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      //border: 1px solid $color_white;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
      i {
        color: $color_white;
      }

      //pointer-events: none;

    }

    &.no-video {
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color_black;
        opacity: 0.2;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        pointer-events: none;
      }
    }

    &.playing {
      background: none !important;
      &:before {
        opacity: 0;

      }
      .play-pause {
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        pointer-events: none;
      }
    }

  }
  .inner-content {
    margin-top: $padding_content_small;
    margin-bottom: 1.8em;
  }

  video, iframe {
    max-width: 100%;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .placeholder.playing {
    video, iframe {
      visibility: visible;
      pointer-events: auto;
    }

  }

  @media screen and (min-width: $media_sm) {
    .inner-wrapper {
      padding-left: 3em;
    }

    &.Right, .right {
      .col-md-6:nth-child(1) {
        -ms-flex-order: 2;
        order: 2;
      }
      .inner-wrapper {
        padding-left: 0;
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    .placeholder {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-bottom: 15px;
      padding-top: 60%;
    }
  }
}
