section.single-image {
  .heading-container {
    margin-bottom: $padding_content;
    //.content {
    //  font-weight: 600;
    //}

  }

  .placeholder {
    padding-top: 60%;
    width: 100%;
  }

  .placeholder-wrapper {
    position: relative;
    width: 100%;
    ul {
      position: absolute;
      bottom: 0px;
      left: 20px;
      padding-left: 0;
      list-style: none;

      li {
        position: relative;
        display: inline-block;
        -webkit-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -ms-transition: opacity 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;

        a {
          //margin-bottom: 10px;
          display: block;
          text-decoration: none;
          padding: 5px 15px 0px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          border-radius: 20px;
          font-size: 20px;
          font-weight: 500;
          background-color: #fff;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          &.coming-soon {
            pointer-events: none;
          }
        }

        .soon-msg {
          color: $color_white;
          font-size: 10px;
          font-weight: 600;
          z-index: 1;
          width: 90px;
          height: 20px;
          text-align: center;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 0;
          transform: translateY(0px);
          visibility: hidden;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 5px;
          text-transform: uppercase;
        }
      }

      &.hover {
        li {
          opacity: 0.3;
        }
        li:hover {
          opacity: 1;
          .soon-msg {
            transform: translateY(-18px);
            visibility: visible;
            z-index: -1;
          }
        }
      }
    }

  }

  .content {
    margin-top: 15px;
  }

  /*if side block*/
  &.has-side-block {
    .wrapper {

      overflow: hidden;
      .image-placeholder {
        position: relative;
        img {
          visibility: hidden;
        }

        .bg-pattern {
          position: absolute;
          z-index: 99;
          top: 50%;
          left: 0;
          max-width: 200px;
          max-height: 200px;
          width: 20vw;
          height: 20vw;
          transform: translate(-50%, -50%);
        }
      }

      .size-1 {
        color: $color_red_dark;
        span {
          color: $color_red;
          display: block;
        }
      }
    }
  }
  @media screen and (min-width: $media_sm) {
    .col-md-7 {
      .placeholder-wrapper {
        padding-right: 15px;
      }
    }
    &.Left, .left {
      .col-md-7 {
        -ms-flex-order: 2;
        order: 2;
        .placeholder-wrapper {
          padding-left: 15px;
          padding-right: 0;
        }
      }
      .col-md-5 {
        -ms-flex-order: 1;
        order: 1;
      }
    }

  }

  @media screen and (max-width: $media_md) {
    &.has-side-block {
      .bg-pattern {
        display: none;
      }
      .wrapper {
        .col-lg-5 {
          padding: $padding_content 15px;
        }
      }
    }
  }
  @media screen and (max-width: $media_sm) {

    .placeholder {
      margin-bottom: $padding_content;
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-bottom: 15px;
    }

    .placeholder-wrapper {

      ul {
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
        position: absolute;
        top: auto;
        bottom: -15px;
        left: -15px;
        width: calc(100% + 30px);
        margin-top: 20px;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        li {
          display: inline-block;
          left: 0 !important;
          top: 0 !important;
          img {
            display: none;
          }
          a {
            margin-bottom: 0;
            min-width: 112px;
            text-align: center;
          }
        }
      }

    }
  }

  @media (pointer: none), (pointer: coarse) {
    .placeholder-wrapper {

      overflow: hidden;
      ul {
        &::-webkit-scrollbar {
          height: 0;
          width: 0;
          display: none !important;
        }
      }
    }
  }

}