section.three-block {
  .heading-container {
    margin-bottom: $padding_content;

    .size-1 {
      margin-bottom: -10px;
      margin-bottom: 55px;
    }

    .summary {
      margin-top: 20px;
    }
  }

  .item-wrapper {
    width: 100%;
  }

  .content {
    margin-top: 10px;
    width: 100%;

    .size-2 {
      margin: $padding_content_small 0;
      //color: $color_blue;
    }

    h4,
    h5,
    p {
      color: #fff;
    }

    .more {
      margin-top: $padding_content_small;
      text-decoration: none;
      font-weight: $fw_medium;
      display: block;
    }
  }

  &.bg-gray {
    .content {
      //background-color: $color_blue_dark;
      h2,
      h4,
      h5 {
        //color: $color_white;
        text-transform: uppercase;
      }

      p {
        //color: $color_white;
      }

      .inner-content {
        //color: $color_white;
      }
    }
  }

  .placeholder {
    margin-bottom: 20px;
    //height: 80px;
    //width: 80px;
    //-webkit-border-radius: 50%;
    //-moz-border-radius: 50%;
    //border-radius: 50%;
    a {
      overflow: hidden;
      width: 80px;
      padding-top: 80px;
      position: relative;
      display: block;
    }
  }

  &.text-center {
    .placeholder {
      margin-left: auto;
      margin-right: auto;

      a {
        display: flex;
        height: 100%;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  @media screen and (min-width: $media_lg) {
    &.text-center {
      .content {
        .inner-content {
          padding-left: 25px;
        }
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    text-align: center;

    &.Left {
      text-align: center;
    }

    .heading-container {
      margin-bottom: 0;

      .size-1 {
        margin-bottom: 50px;
      }

      .size-2 {
        margin-bottom: 30px;
      }
    }

    .placeholder {
      margin-left: auto;
      margin-right: auto;

      a {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .content {
      width: 100%;

      .size-2 {
        margin: 15px 0;
      }
    }
  }
}
/*three block bg-primary*/
section.three-block.block-bg-gray,
section.three-block.block-bg-primary {
  .col-md {
    display: flex;
  }

  .item-wrapper {
    position: relative;
    text-align: left;
    padding: 15px;
    width: 100%;

    .placeholder {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 50px;
      height: 50px;

      a {
        padding-top: 0;
        width: 100% !important;
        height: 100% !important;
        box-sizing: border-box;
      }
    }

    .size-3 {
      padding-top: 5px;
      padding-bottom: 25px;
      display: block;
      border-bottom: 2px solid $color_white;
      padding-right: 50px;
    }

    a.more {
      @extend %btn;
      font-size: 16px;
      z-index: 1;
      min-width: auto;
    }
  }
}

section.three-block.block-bg-primary {
  .item-wrapper {
    * {
      color: $color_white;
    }
  }
}