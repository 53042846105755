
/*==latest-news*/
section.latest-news {

  .tag-section {
    display: none;
    margin-bottom: $padding_content_laptop;
    ul {
      display: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;
      }
      li:not(:last-child) {
        margin-right: 10px;
      }

    }
  }
  .filter-selected {
    margin-bottom: $padding_content_laptop;
    background-color: $color_line_black;
    padding: 10px;
    ul {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      li:not(:last-child) {
        margin-right: 10px;
      }
      .material-icons {
        margin-top: -5px;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .heading-container {
    margin-bottom: 2.5em;
    justify-content: center;

    h1, h2, h3 {
      margin-bottom: 0;
      //align-self: center;

    }

    .inner-content {
      position: relative;
      width: 100%;
      //hr{
      //  position: absolute;
      //  width: 100%;
      //  bottom: -24px;
      //  left: 0px;
      //  margin: 0 auto;
      //}
      .line {
        position: absolute;
        bottom: -15px;
        width: 100%;
        height: 1px;
        left: 0;
        border-top: 1px solid $color_line_black;
      }
      //&:after {
      //  content: '';
      //  position: absolute;
      //  bottom: -15px;
      //  width: 100%;
      //  height: 1px;
      //  left: 0;
      //  background-color: $color_line_black;
      //}
    }
    .right {
      display: inline-flex;
      margin-top: -10px;
    }

  }

  .content-wrapper {
    position: relative;

    width: 100%;
    padding-bottom: 45px;
    //border: 1px solid $color_bg_gray;

    .popup-video-play {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
    }
    .content {
      padding: 20px;
      .size-4 {
        //color: $color_black;
        text-transform: uppercase;

      }
      .published {
        display: block;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .inner-content {
        margin: 15px 0 0px;

      }
    }

    .placeholder-wrapper {
      position: relative;
      overflow: hidden;
      .blog-info {
        position: absolute;
        bottom: -3px;
        left: 0;
        padding: 0;
        .btn-primary {
          &:before {
            width: calc(100% + 30px);
            left: -22px;
            top: -3px;
          }
        }
      }
    }

    .more {
      position: absolute;
      bottom: 20px;
      left: 20px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: $fw_medium;
      i {
        color: #fff !important;
        margin-left: 10px;
        font-size: 20px;
        opacity: 1;
      }
    }

    .placeholder {
      min-height: 250px;
      position: relative;
      overflow: hidden;

      i {
        font-size: 60px !important;
        color: #fff;
        opacity: 0.8;
        position: relative;
        z-index: 999;
        &:hover {
          opacity: 1;
        }
      }
      .inner-image {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          //background: linear-gradient(180deg, $color_blue 0%, $color_blue_dark 100%);
          width: 100%;
          height: 100%;
          opacity: 0;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        &.portrait {
          background-position: top left !important;
        }
      }

      &:hover {
        .inner-image {
          transform: scale(1.2);
          &:after {
            opacity: 0.5;
          }
        }

      }
    }

  }

  .blog-grid-container {
    margin-bottom: -30px;
    .col-md-4 {
      margin-bottom: 30px;
    }
  }

  &.bg-gray {
    .content-wrapper {
      background-color: $color_white;
      &:before {
        //border-top-color: $color_bg_gray;
        //background-color: $color_bg_gray;
      }
    }
  }
  @media screen and (min-width: $media_md) {
    .heading-container {
      h2 {
        max-width: 80%;
      }
    }
    .btn-gray {
      width: 150px;
      margin-left: 10px;
    }

  }

  .tag-section {
    ul {
      margin-bottom: -10px;
    }
  }

}
