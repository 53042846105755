section.intro.intro-content {
  a.more {
    margin-top: 0.8em;
    &.align-right {
      margin-left: auto;
      display: table;
    }
  }
  @media screen and (max-width: 767px){
    .col-md-5 > .content{
      margin-bottom: 30px;
    }
  }
}