
section.two-block {
  .heading-container {
    margin-bottom: $padding_content;
  }
  .content {
    background-color: #fff;
    width: calc(100% - 60px);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 20px;
    min-height: 197px;
    position: relative;

    h3 {
      color: $color_black;
    }

    .inner-content {
      margin: 10px 0 15px;
      color: #fff;
    }
  }

  &.bg-gray {
    .content {
      background-color: #fff;
    }

    h3, p {
      color: $color_black;
    }

  }

  .placeholder {
    height: 420px;
    margin-bottom: -120px;
  }

  &.related-section {
    .heading-container {
      padding-bottom: $padding_content;
      margin-bottom: 0;
    }
    .content {
      padding: 30px;
      min-height: auto;
    }
    .more {
      margin-top: 10px;
    }

  }

}

section.two-block-small {
  .block-repeat {
    margin-top: 1.875em;
    //background-color: $color_blue_dark;
    .placeholder {
      height: 100%;
      width: 100%;
    }

    .left-content {
      width: 300px;
      height: 100%;
      min-height: 300px;
    }

    .right-content {
      width: calc(100% - 300px);
      min-height: 300px;
    }

    .content-wrapper {
      padding: 50px;
      * {
        color: #fff;
      }
      .content, h3, h2 {
        color: #fff;
      }
      a.btn {
        margin-top: 15px;
      }

    }
  }

  &.block-repeat-not-margin {
    .block-repeat:first-child {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: $media_md) {
    .block-repeat {

      .left-content {
        width: 100%;
        height: 215px;

      }

      .right-content {
        width: 100%;
        min-height: 215px;
      }
    }
  }

  @media screen and (max-width: $media_xs) {
    .block-repeat {
      .left-content {
        min-height: auto;
        .placeholder {
          margin-left: -15px;
          width: calc(100% + 30px);
        }
      }
      .content-wrapper {
        padding: 15px;
      }
    }
  }
}
