section.for-sale-carousel {
  //padding-bottom: $padding_section;
  .owl-stage-outer {
    overflow: visible;
  }
  .item {
    min-height: 500px;
    margin-bottom: 100px;
  }

  .content {
    margin: auto;
    position: relative;
    z-index: 99;
    width: calc(100% - 200px);
    top: calc(500px - 125px);
    //bottom: 0;
    border: 1px solid $color_bg_gray_light;
    background-color: #fff;
    padding: 30px;
    .size-6 {
      color: $color_black;
      font-weight: 600;
    }
    .inner-content {
      margin: 10px 0 15px;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .btn {
      margin-top: 0;
    }
  }
  .brand {
    font-size: 20px;
    font-weight: 500;
    color: $color_black;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    margin-left: 12px;
    padding: 5px 15px 0px 5px;
    //-webkit-border-radius: 20px;
    //-moz-border-radius: 20px;
    //border-radius: 20px;
    position: relative;
    .trangle {
      z-index: -1;
      width: 17px;
      height: calc(100% + 6px);
      position: absolute;
      left: -7px;
      transform: skewX(-20deg);
      top: -3px;
    }

    .brand-img {
      margin-right: 10px;
      img {
        width: 40px;
      }
    }

  }

  .owl-item:not(.center) {
    opacity: 0.4;
  }
  @media screen and (max-width: $media_xs) {
    .item {
      min-height: 200px;
    }
  }

}