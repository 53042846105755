section.logo-panel {
  .inner-wrapper {
    .content {
      margin-top: 15px;
      .inner-content {
        max-width: 800px;
        margin: 0 auto;
      }

    }
  }

  .logo-panel-wrapper {
    position: relative;
  }

  /*==owl carousel*/
  .owl-theme {
    overflow: hidden;
    margin-top: $padding_content;

    .item {
      .image-placeholder {
        padding-top: 60%;
        width: 60%;
        margin: 0 auto;
        &:before, &:after {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          object-fit: contain;
        }
      }
    }
    .owl-nav {
      display: none;

    }
    .owl-stage {
      display: inline-flex;
    }
    .owl-dots {
      margin-top: 0;

      text-align: left;
      .owl-dot {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        padding: 6px;
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0);
        margin-right: 3px;
        span {
          height: 6px;
          width: 6px;
          padding: 0;
          margin: 0;
          color: $color_light_black;
        }
        &.active, &:hover {
          border: 1px solid $color_light_black;
        }
        &:focus {
          outline-style: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }

  }

  /*==left right column*/

  .half-logo-wrapper {
    .item {
      width: 49.6%;
    }
  }

  /*==navdisable when nav disabled*/
  .owl-theme:not(.logo-panel-carousel) {
    //display: inline-flex;
    .item {
      width: 19.6%;
      display: inline-flex;
    }
    @media screen and (max-width: $media_md_) {
      .item {
        width: 24.6%;
        display: inline-flex;
      }
    }
    @media screen and (max-width: $media_sm_) {
      .item {
        width: 32.6%;
        display: inline-flex;
      }
    }
    @media screen and (max-width: $media_xs_) {
      .item {
        width: 49.5%;
        display: inline-flex;
      }
    }
  }

  .owl-theme.half-logo-wrapper{
    //display: inline-flex;
    margin-top: 0;
    .item {
      width: 49.5%;
      display: inline-flex;
      .image-placeholder{
        max-width: 150px;
      }
    }

    @media screen and (max-width: $media_md_) {
      .item {
        width: 49%;

      }
    }
    @media screen and (max-width: $media_xs_) {
      margin-top: $padding_content_small;
      .item {
        width: 49.5%;

      }
    }
  }

  /*==two row*/
  .owl-theme.two-row {
    .item {
      .image-placeholder:first-of-type {
        margin-bottom: 15px;
      }
    }
  }

  /*nav-wrapper*/
  #logo-panel-nav-container {
    margin-top: $padding_content;
    &.owl-nav {
      display: flex !important;
      justify-content: center;
      width: 100%;
      left: 0;
      button {
        margin: 0 5px;
        background-color: transparent;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        @extend %noselect;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

  }

  /*==middle*/
  .logo-panel-wrapper.nav-middle {
    #logo-panel-nav-container {
      position: absolute;
      top: 50%;
      left: 0;
      justify-content: space-between;
      margin-top: 0;
      transform: translateY(-50%);
      width: 120%;
      left: -10%;
      max-width: 100vw;
    }

    @media screen and (max-width: $media_xxl) {
      #logo-panel-nav-container {
        width: 110%;
        left: -5%;
      }
    }

    @media screen and (max-width: $media_xl) {
      width: 100%;
      left: 0;
      #logo-panel-nav-container {
        width: 100%;
        left: 0%;
      }
    }

    @media screen and (max-width: $media_sm_) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      #logo-panel-nav-container {
        width: 130%;
        left: -15%;
      }
    }
  }

 @media screen and (min-width: 768px){
    &.content-right{
      .row >{
        .col-md-6{
          order: 1;
        }
        .col-md-6:first-of-type{
          order: 2;
        }
      }

    }
 }
  @media screen and (max-width: $media_sm_) {

    .size-2 {
      br {
        display: none;
      }
    }
  }

}